<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>楼栋名称：</span>
            <input v-model="pageInfo.name" placeholder="名称" class="searchInput" />
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">楼栋列表</div>
          <div><button class="addBtn" @click="showEdit('')"> + 新增</button></div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="code" label="编号" />
          <el-table-column prop="name" label="名称" />
          <el-table-column prop="labSize" label="楼栋实验室数量" />
          <el-table-column prop="time" label="创建时间" />
<!--          <el-table-column fixed="right" label="数据看板" width="150">
            <template #default="scope">&lt;!&ndash; scope.$index 表示 序列号 0、1、2 &ndash;&gt;
              <el-button link type="primary" style="color: #3769B9" @click="goAppoinmentBand(scope.row.id,scope.row.name)">查看</el-button>
            </template>
          </el-table-column>-->
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">编辑</el-button>
<!--              <el-button link type="primary" style="color: #3769B9" @click="showRenew(scope.row.id)">删除</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
    <editDiv v-model="dialogFormVisible" :Row="Info" @closeDiv="closeDiv" @getList="getList" ref="editDiv"></editDiv>
  </div>
</template>

<script>
import editDiv from "@/view/building/buildingEdit.vue"
import {getBuildingPage} from "@/api/api";
export default {
  name: "buildingList",
  components:{
    editDiv,
  },
  data(){
    return{
      tableData:[],
      dataInfo:[],
      Info:null,
      dialogFormVisible:false,
      pageInfo:{
        limit:10,
        name:'',
        page:1,
        total:0
      },
      exportIds:''
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    reset(){
      this.pageInfo.name=''
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getBuildingPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    showEdit(row){
      this.dialogFormVisible=true;
      this.Info=row;
      let data='';
      if(row) {
        data = JSON.stringify(this.Info);
      }
      this.$refs.editDiv.getInfo(data);//调用子组件方法
    },
    showRenew(id){
      this.dialogFormVisible1=true;
      this.$refs.renewDiv.getInfo(id);//调用子组件方法
    },
    goAppoinmentBand(buildingId,buildingName){
      //this.$root.useRouter.push({path:"/building/buildingBand",query:{buildingId:buildingId}})
      window.open("/#/building/buildingBand?buildingId="+buildingId+"&buildingName="+buildingName,"_blank");
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>